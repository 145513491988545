<template
  ><v-form>
    <v-layout>
      <v-app-bar app height="124" class="align-start elevation-1">
        <v-col cols="12" class="py-0">
          <v-row dense class="align-center flex-nowrap">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>Artikel kopieren</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="$router.go(-1)">
                <v-icon>mdi-cancel</v-icon>
                <span class="ml-1 d-none d-lg-flex">Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1 grey--text text--lighten-4" @click="artikelSpeichern()" :disabled="validInsert || checkNames">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Speichern</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="newDatasetEvent.Bearbeiter_ID"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>

      <v-container fluid class="px-0 pt-0">
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="12">
                      <v-toolbar-title>
                        Hauptdaten
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="6" md="7" sm="8">
                      <span class="font-weight-bold">Art.-Nr.(*)</span>
                      <v-text-field
                        v-model="editableDataset.Artikelnummer"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Artikelnummer"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense align="start">
                    <v-col cols="12" sm="7" md="4" lg="4" xl="4">
                      <span class="font-weight-bold">Kategorie(*)</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        required
                        open-on-clear
                        v-model="editableDataset.Abteilung_ID"
                        :items="Abteilungen"
                        item-text="Abteilung"
                        item-value="Abteilung_ID"
                        placeholder="Abteilung"
                        @input="clearGroupAndSubgroup()"
                        ><template v-slot:no-data>
                          <span class="text-center d-block">keine Abteilung gefunden</span>
                        </template>
                      </v-select>
                      <div class="pt-1" v-if="editableDataset.Abteilung_ID">
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          v-model="editableDataset.Artikelgruppe_ID"
                          :items="filteredArtikelgruppen"
                          item-text="Artikelgruppe"
                          item-value="Artikelgruppe_ID"
                          placeholder="Gruppe"
                          clearable
                          :disabled="!editableDataset.Abteilung_ID && filteredArtikelgruppen.length === 0"
                          @click:clear="clearSubgroup()"
                          @input="clearSubgroup()"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">nicht vorhanden</span>
                          </template>
                        </v-select>
                      </div>
                      <div class="pt-1" v-if="editableDataset.Artikelgruppe_ID">
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          clearable
                          v-model="editableDataset.Artikeluntergruppe_ID"
                          :items="filteredArtikeluntergruppen"
                          item-text="Artikeluntergruppe"
                          item-value="Artikeluntergruppe_ID"
                          placeholder="Untergruppe"
                          :disabled="!editableDataset.Artikelgruppe_ID && filteredArtikeluntergruppen.length === 0"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">nicht vorhanden</span>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" lg="2" xl="2">
                      <span class="font-weight-bold">Größe(*)</span>
                      <v-text-field
                        v-model="editableDataset.Größe"
                        hide-details
                        dense
                        outlined
                        clearable
                        required
                        placeholder="Größe"
                        @click:clear="$nextTick(() => clearArticlename())"
                        @input="clearArticlename()"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="5" lg="6" xl="6">
                      <span class="font-weight-bold">Artikelname(*)</span>
                      <v-combobox
                        v-model="editableDataset.Artikelname"
                        :items="filteredArtikelnamen"
                        :error="checkNames"
                        :disabled="!editableDataset.Abteilung_ID || !editableDataset.Größe"
                        item-value="Artikelname_ID"
                        item-text="Artikelname"
                        dense
                        outlined
                        placeholder="Artikelname"
                        hide-details
                        clearable
                        open-on-clear
                        ><template v-slot:no-data>
                          <span class="text-center d-block">bitte neuen Artikelnamen eingeben</span>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Bestand</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        v-model="editableDataset.Bestand"
                        placeholder="1"
                        @click:clear="$nextTick(() => clearBestand())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Einheit</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        v-model="editableDataset.Einheit_ID"
                        :items="Einheiten"
                        item-text="Einheit"
                        item-value="Einheit_ID"
                        placeholder="Einheit"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">bestandsgeführt</span>
                      <v-checkbox
                        dense
                        hide-details
                        class="pb-1 pt-1 mt-0"
                        v-model="editableDataset.ist_bestandsgeführt"
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense align="center">
                    <v-col v-if="editableDataset.ist_bestandsgeführt === 1" cols="12" xl="4" lg="4" md="4" sm="4">
                      <span class="font-weight-bold">Mindestbestand</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        v-model="editableDataset.Mindestbestand"
                        placeholder="1"
                        @click:clear="$nextTick(() => clearMindestbestand())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-if="editableDataset.Status_ID && editableDataset.Status_ID !== 1">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Eigenschaften / Details
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="start">
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Farben</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="editableDataset.Hauptfarbe_ID"
                        :items="Hauptfarben"
                        item-text="Farbe"
                        item-value="Farb_ID"
                        placeholder="Hauptfarbe"
                      >
                      </v-autocomplete>
                      <div class="pt-1" v-if="editableDataset.Hauptfarbe_ID">
                        <v-autocomplete
                          hide-details
                          dense
                          outlined
                          clearable
                          open-on-clear
                          v-model="editableDataset.Nebenfarbe_ID"
                          :items="Nebenfarben"
                          item-text="Farbe"
                          item-value="Farb_ID"
                          placeholder="Nebenfarbe"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Stoffe</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="editableDataset.Hauptstoff_ID"
                        :items="Hauptstoffe"
                        item-text="Stoff"
                        item-value="Stoff_ID"
                        placeholder="Hauptstoff"
                      >
                      </v-autocomplete>
                      <div class="pt-1" v-if="editableDataset.Hauptstoff_ID">
                        <v-autocomplete
                          hide-details
                          dense
                          outlined
                          clearable
                          open-on-clear
                          v-model="editableDataset.Nebenstoff_ID"
                          :items="Nebenstoffe"
                          item-text="Stoff"
                          item-value="Stoff_ID"
                          placeholder="Nebenstoff"
                        >
                        </v-autocomplete>
                      </div>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Schnitt</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="editableDataset.Schnitt_ID"
                        :items="Schnitte"
                        item-text="Schnitt"
                        item-value="Schnitt_ID"
                        placeholder="Schnitt"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="6" lg="8" md="10" sm="10">
                      <span class="font-weight-bold">Besonderheiten</span>
                      <v-textarea
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="editableDataset.Besonderheiten"
                        placeholder="Besonderheiten / Beschreibung"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" class="d-none">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Bild</span>
                      <v-file-input
                        v-model="editableDataset.Bild"
                        show-size
                        truncate-length="20"
                        clearable
                        placeholder="Bild hochladen"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Hersteller</span>
                      <v-autocomplete
                        hide-details
                        dense
                        outlined
                        clearable
                        open-on-clear
                        v-model="editableDataset.Hersteller_ID"
                        :items="Hersteller"
                        item-text="Name"
                        item-value="Hersteller_ID"
                        placeholder="Hersteller"
                        @change="editableDataset.Bestellstatus_ID = 2"
                        @click:clear="editableDataset.Bestellstatus_ID = 2"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="editableDataset.Hersteller_ID" cols="12" xl="5" lg="5" md="6" sm="6" class="mt-auto"
                      ><span class="font-weight-bold">nicht mehr bestellbar</span>
                      <v-checkbox
                        dense
                        hide-details
                        class="pb-1 pt-1 mt-0"
                        v-model="editableDataset.Bestellstatus_ID"
                        :true-value="1"
                        :false-value="2"
                      ></v-checkbox
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center" v-if="editableDataset.Status_ID && editableDataset.Status_ID !== 1">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Preise
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">MwSt.-Satz</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        v-model="Berechnungen.MwSt"
                        :items="MwSt"
                        item-text="MwSt"
                        item-value="MwSt_ID"
                        return-object
                        @change="VK_brutto_Berechnung()"
                        placeholder="MwSt.-Satz"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">EK netto <span v-if="checkCurrentUser">(normal)</span></span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.EK_netto"
                        @change="VK_brutto_Berechnung()"
                        @click:clear="$nextTick(() => clearEKnetto())"
                        placeholder="0,00"
                        suffix="€"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="checkCurrentUser" cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">EK netto</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.EK_netto_spezial"
                        @change="formatEKnettoSpezial()"
                        @click:clear="$nextTick(() => clearEKnettospezial())"
                        placeholder="0,00"
                        suffix="€"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center" class="pt-6">
                    <v-col cols="12" xl="7" lg="8" md="9" sm="10">
                      <v-slider
                        v-model="Berechnungen.Kalkulation"
                        class="align-center"
                        max="5.00"
                        min="0.00"
                        hide-details
                        :disabled="!Berechnungen.EK_netto || !Berechnungen.VK_brutto"
                        thumb-label="always"
                        :thumb-size="42"
                        step="any"
                        @input="VK_brutto_Berechnung()"
                      >
                        <template v-slot:thumb-label="{ value }">
                          {{ (value * 100).toFixed(0) + "%" }}
                        </template>
                      </v-slider>
                      <span class="font-weight-bold">Kalkulation</span>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">VK brutto</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.VK_brutto"
                        placeholder="0,00"
                        suffix="€"
                        @change="Kalkulation_Berechnung()"
                        @click:clear="$nextTick(() => clearVKbrutto())"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">Leihpreis</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="Berechnungen.Leihpreis"
                        placeholder="0,00"
                        suffix="€"
                        @change="$nextTick(() => formatLeihpreis())"
                        @click:clear="$nextTick(() => clearLeihpreis())"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-layout>

    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
  </v-form>
</template>

<script>
import LeavePathWrapper from "../../../../components/dialogs/LeavePathWrapper.vue";
import LeavePathDialog from "../../../../components/dialogs/LeavePathDialog.vue";

export default {
  components: {
    LeavePathWrapper,
    LeavePathDialog,
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.saved === true || this.checkDifferences === false) {
      next();
    } else if (this.saved === false) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data: () => ({
    Abteilungen: [],
    Artikelgruppen: [],
    Artikeluntergruppen: [],
    Artikelnamen: [],
    Hauptfarben: [],
    Nebenfarben: [],
    Hauptstoffe: [],
    Nebenstoffe: [],
    Schnitte: [],
    Hersteller: [],
    Einheiten: [],
    MwSt: [],
    Bearbeiter: [],
    Berechnungen: {
      EK_netto: "",
      EK_netto_spezial: "",
      VK_brutto: "",
      Kalkulation: "",
      EK_brutto: "",
      VK_netto: "",
      Leihpreis: "",
      MwSt: {
        MwSt_ID: 1,
        MwSt: "19%",
        Satz: 1.19,
      },
    },
    editableDataset: {},
    initialDataset: {},
    defaultDatasetEvent: {
      Vorgang_ID: null,
      Artikel_ID: null,
      VK_brutto: null,
      Leihpreis: null,
      EK_netto: null,
      // Datum wird automatisch erstellt
      Bearbeiter_ID: null,
    },
    newDatasetEvent: {},
    saved: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "andre") {
        return true;
      } else return false;
    },

    checkNames() {
      const findname = this.Artikelnamen.filter(
        (item) => item.Artikelname === this.editableDataset.Artikelname && item.Größe === this.editableDataset.Größe && item.Artikelname !== null
      );
      if (findname.length > 0) {
        return true;
      } else return false;
    },

    filteredArtikelnamen() {
      if (this.editableDataset.Abteilung_ID && !this.editableDataset.Größe) {
        return this.Artikelnamenliste.filter((item) => item.Abteilung_ID === this.editableDataset.Abteilung_ID);
      } else if (!this.editableDataset.Abteilung_ID && this.editableDataset.Größe) {
        return this.Artikelnamenliste.filter((item) => item.Größe === this.editableDataset.Größe);
      } else if (this.editableDataset.Abteilung_ID && this.editableDataset.Größe) {
        return this.Artikelnamenliste.filter(
          (item) => item.Abteilung_ID === this.editableDataset.Abteilung_ID && item.Größe === this.editableDataset.Größe
        );
      } else return [];
    },
    filteredArtikelgruppen() {
      if (this.editableDataset.Abteilung_ID) {
        return this.Artikelgruppen.filter((item) => item.Abteilung_ID === this.editableDataset.Abteilung_ID);
      } else return [];
    },

    filteredArtikeluntergruppen() {
      if (this.editableDataset.Artikelgruppe_ID) {
        return this.Artikeluntergruppen.filter((item) => item.Artikelgruppe_ID === this.editableDataset.Artikelgruppe_ID);
      } else return [];
    },

    checkDifferences() {
      if (
        this.editableDataset.Abteilung_ID === this.initialDataset.Abteilung_ID &&
        this.editableDataset.Artikelgruppe_ID === this.initialDataset.Artikelgruppe_ID &&
        this.editableDataset.Artikeluntergruppe_ID === this.initialDataset.Artikeluntergruppe_ID &&
        this.editableDataset.Artikelnummer === this.initialDataset.Artikelnummer &&
        this.editableDataset.Artikelname_ID === this.initialDataset.Artikelname_ID &&
        this.editableDataset.Größe === this.initialDataset.Größe &&
        this.editableDataset.Status_ID === this.initialDataset.Status_ID &&
        this.editableDataset.Hauptfarbe_ID === this.initialDataset.Hauptfarbe_ID &&
        this.editableDataset.Nebenfarbe_ID === this.initialDataset.Nebenfarbe_ID &&
        this.editableDataset.Hauptstoff_ID === this.initialDataset.Hauptstoff_ID &&
        this.editableDataset.Nebenstoff_ID === this.initialDataset.Nebenstoff_ID &&
        this.editableDataset.Schnitt_ID === this.initialDataset.Schnitt_ID &&
        this.editableDataset.Besonderheiten === this.initialDataset.Besonderheiten &&
        this.editableDataset.Hersteller_ID === this.initialDataset.Hersteller_ID &&
        this.Berechnungen.EK_netto === this.initialDataset.EK_netto &&
        this.Berechnungen.EK_netto_spezial === this.initialDataset.EK_netto_spezial &&
        this.Berechnungen.EK_brutto === this.initialDataset.EK_brutto &&
        this.Berechnungen.VK_netto === this.initialDataset.VK_netto &&
        this.Berechnungen.VK_brutto === this.initialDataset.VK_brutto &&
        this.Berechnungen.Leihpreis === this.initialDataset.Leihpreis &&
        this.Berechnungen.MwSt.MwSt_ID === this.initialDataset.MwSt_ID &&
        this.editableDataset.Bestand === this.initialDataset.Bestand &&
        this.editableDataset.Einheit_ID === this.initialDataset.Einheit_ID &&
        this.editableDataset.Mindestbestand === this.initialDataset.Mindestbestand
      ) {
        return false;
      } else return true;
    },

    validInsert() {
      if (
        !this.newDatasetEvent.Bearbeiter_ID ||
        !this.editableDataset.Abteilung_ID ||
        !this.editableDataset.Artikelnummer ||
        !this.editableDataset.Größe ||
        !this.editableDataset.Artikelname
      ) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/database/articles/dataset/edit/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.editableDataset.Artikelname_ID = null;
      this.editableDataset.Artikelname = null;
      this.editableDataset.Bestand = 1;
      this.editableDataset.ist_bestandsgeführt = 0;
      this.editableDataset.Mindestbestand = null;
      this.editableDataset.Bestellstatus_ID = 2;
      this.initialDataset = Object.assign({}, this.editableDataset);
      this.newDatasetEvent = Object.assign({}, this.defaultDatasetEvent);
      this.Berechnungen.EK_netto = this.editableDataset.EK_netto;
      this.Berechnungen.EK_netto_spezial = this.editableDataset.EK_netto_spezial;
      this.Berechnungen.EK_brutto = this.editableDataset.EK_brutto;
      this.Berechnungen.VK_netto = this.editableDataset.VK_netto;
      this.Berechnungen.VK_brutto = this.editableDataset.VK_brutto;
      this.Berechnungen.Kalkulation = this.editableDataset.Kalkulation;
      this.Berechnungen.MwSt.MwSt_ID = this.editableDataset.MwSt_ID;
      this.Berechnungen.Leihpreis = this.editableDataset.Leihpreis;
    },

    clearGroupAndSubgroup() {
      this.editableDataset.Artikelgruppe_ID = null;
      this.editableDataset.Artikeluntergruppe_ID = null;
      this.clearArticlename();
    },

    clearSubgroup() {
      this.editableDataset.Artikeluntergruppe_ID = null;
    },

    clearArticlename() {
      this.editableDataset.Artikelname = null;
    },

    VK_brutto_Berechnung() {
      if (this.Berechnungen.EK_netto) {
        const eknetto = this.Berechnungen.EK_netto;
        const kalk = this.Berechnungen.Kalkulation;
        const mwst = this.Berechnungen.MwSt.Satz;

        const eknetto_string = String(parseFloat(eknetto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const ekbrutto = parseFloat(eknetto.replace(",", ".") * mwst);
        const ekbrutto_string = String(ekbrutto.toFixed(2)).replace(".", ",");

        if (kalk == 0) {
          var vknetto = parseFloat(eknetto.replace(",", "."));
        } else if (kalk > 0) {
          var vknetto = parseFloat(eknetto.replace(",", ".") * kalk);
        }
        const vknetto_string = String(vknetto.toFixed(2)).replace(".", ",");

        const vkbrutto = (vknetto * mwst).toFixed(2);
        const vkbrutto_string = String(vkbrutto.replace(".", ","));

        this.Berechnungen.EK_netto = eknetto_string;
        this.Berechnungen.EK_brutto = ekbrutto_string;
        this.Berechnungen.VK_brutto = vkbrutto_string;
        this.Berechnungen.VK_netto = vknetto_string;
      }
    },

    Kalkulation_Berechnung() {
      if (this.Berechnungen.EK_netto && this.Berechnungen.VK_brutto) {
        const vkbrutto = this.Berechnungen.VK_brutto;
        const mwst = this.Berechnungen.MwSt.Satz;
        const eknetto = this.Berechnungen.EK_netto;

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const kalk = parseFloat(vkbrutto.replace(",", ".")) / (mwst * eknetto.replace(",", "."));

        const leihpreis = parseFloat(vkbrutto.replace(",", ".")) / 3;
        const leihpreis_string = String(leihpreis.toFixed(2)).replace(".", ",");

        this.Berechnungen.VK_brutto = vkbrutto_string;
        this.Berechnungen.Kalkulation = kalk;
        this.Berechnungen.VK_netto = vknetto_string;
        this.Berechnungen.Leihpreis = leihpreis_string;
      } else if (!this.Berechnungen.EK_netto && this.Berechnungen.VK_brutto) {
        const mwst = this.Berechnungen.MwSt.Satz;
        const vkbrutto = this.Berechnungen.VK_brutto;
        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        this.Berechnungen.VK_netto = vknetto_string;
        this.Berechnungen.VK_brutto = vkbrutto_string;
      } else if (this.Berechnungen.EK_netto && !this.Berechnungen.VK_brutto) {
      }
    },

    formatLeihpreis() {
      if (this.Berechnungen.Leihpreis) {
        const leihpreis = this.Berechnungen.Leihpreis;
        const leihpreis_string = String(parseFloat(leihpreis.replace(",", ".")).toFixed(2)).replace(".", ",");
        this.Berechnungen.Leihpreis = leihpreis_string;
      }
    },

    formatEKnettoSpezial() {
      const EK_netto_spezial = this.Berechnungen.EK_netto_spezial;
      const EK_netto_spezial_string = String(parseFloat(EK_netto_spezial.replace(",", ".")).toFixed(2)).replace(".", ",");
      this.Berechnungen.EK_netto_spezial = EK_netto_spezial_string;
    },

    formatBestand() {
      const bestand = this.editableDataset.Bestand;
      const bestand_string = String(parseFloat(bestand.replace(",", ".")).toFixed(2)).replace(".", ",");
      this.editableDataset.Bestand = bestand_string;
    },

    clearLeihpreis() {
      this.editableDataset.Leihpreis = null;
    },

    clearBestand() {
      this.editableDataset.Bestand = null;
    },

    clearEKnetto() {
      this.Berechnungen.EK_netto = "";
      this.Berechnungen.EK_brutto = "";
      this.Berechnungen.Kalkulation = null;
      this.Berechnungen.EK_netto_spezial = "";
      this.Ersparnis = "";
    },

    clearEKnettospezial() {
      this.Berechnungen.EK_netto_spezial = "";
      this.Ersparnis = "";
    },

    clearVKbrutto() {
      this.Berechnungen.VK_brutto = "";
      this.Berechnungen.VK_netto = "";
      this.Berechnungen.Kalkulation = null;
    },

    clearLeihpreis() {
      this.Berechnungen.Leihpreis = "";
    },

    async artikelSpeichern() {
      const datasetToInsert = {};
      const articleNameToAdd = {};

      if (this.editableDataset.Artikelname && this.editableDataset.Artikelname.Artikelname_ID) {
        newarticlenameid = this.editableDataset.Artikelname.Artikelname_ID;
      } else if (this.editableDataset.Artikelname && this.filteredArtikelnamen.length > 0) {
        const findname = this.filteredArtikelnamen.filter((item) => item.Artikelname === this.editableDataset.Artikelname);
        if (findname.length > 0) {
          newarticlenameid = findname[0].Artikelname_ID;
        } else {
          if (Object.keys(articleNameToAdd).length === 0) {
            Object.assign(articleNameToAdd, {
              Artikelname: this.editableDataset.Artikelname,
              Abteilung_ID: this.editableDataset.Abteilung_ID,
              Artikelgruppe_ID: this.editableDataset.Artikelgruppe_ID,
              Größe: this.editableDataset.Größe,
              verwendbar: 1,
            });
          }

          const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/name/insert`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(articleNameToAdd),
          });
          const jsonartikel = await saveresponse.json();

          var newarticlenameid = jsonartikel.lastInsertRowid;
        }
      } else if (this.editableDataset.Artikelname === null) {
        var newarticlenameid = null;
      } else {
        if (Object.keys(articleNameToAdd).length === 0) {
          Object.assign(articleNameToAdd, {
            Artikelname: this.editableDataset.Artikelname,
            Abteilung_ID: this.editableDataset.Abteilung_ID,
            Artikelgruppe_ID: this.editableDataset.Artikelgruppe_ID,
            Größe: this.editableDataset.Größe,
            verwendbar: 1,
          });
        }

        const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/name/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleNameToAdd),
        });
        const jsonartikel = await saveresponse.json();

        var newarticlenameid = jsonartikel.lastInsertRowid;
      }

      if (!this.editableDataset.Hersteller_ID) {
        this.editableDataset.Bestellstatus_ID = 1;
      }

      if (this.editableDataset.ist_bestandsgeführt === 0) {
        this.editableDataset.Mindestbestand = null;
      }

      if (!this.Berechnungen.EK_netto || !this.Berechnungen.VK_brutto) {
        this.editableDataset.Kalkulation = null;
      } else this.editableDataset.Kalkulation = this.Berechnungen.Kalkulation;

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Abteilung_ID: this.editableDataset.Abteilung_ID,
          Artikelgruppe_ID: this.editableDataset.Artikelgruppe_ID,
          Artikeluntergruppe_ID: this.editableDataset.Artikeluntergruppe_ID,
          Artikelnummer: this.editableDataset.Artikelnummer,
          Artikelname_ID: newarticlenameid,
          Größe: this.editableDataset.Größe,
          Status_ID: 2,
          Bestellstatus_ID: this.editableDataset.Bestellstatus_ID,
          Hauptfarbe_ID: this.editableDataset.Hauptfarbe_ID,
          Nebenfarbe_ID: this.editableDataset.Nebenfarbe_ID,
          Hauptstoff_ID: this.editableDataset.Hauptstoff_ID,
          Nebenstoff_ID: this.editableDataset.Nebenstoff_ID,
          Schnitt_ID: this.editableDataset.Schnitt_ID,
          Besonderheiten: this.editableDataset.Besonderheiten,
          Hersteller_ID: this.editableDataset.Hersteller_ID,
          VK_netto: this.Berechnungen.VK_netto,
          VK_brutto: this.Berechnungen.VK_brutto,
          Leihpreis: this.Berechnungen.Leihpreis,
          EK_netto: this.Berechnungen.EK_netto,
          EK_netto_spezial: this.Berechnungen.EK_netto_spezial,
          EK_brutto: this.Berechnungen.EK_brutto,
          MwSt_ID: this.Berechnungen.MwSt.MwSt_ID,
          Kalkulation: this.editableDataset.Kalkulation,
          ist_bestandsgeführt: this.editableDataset.ist_bestandsgeführt,
          Bestand: this.editableDataset.Bestand,
          Einheit_ID: this.editableDataset.Einheit_ID,
          Mindestbestand: this.editableDataset.Mindestbestand,
          Bild: null,
          Anzahl_verliehen: 0,
        });
      }

      const saveresponse = await fetch(`/api/database/${this.$route.meta.request}/dataset/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      const jsonartikel = await saveresponse.json();
      const newdatasetid = jsonartikel.lastInsertRowid;

      if (newarticlenameid !== null) {
        await fetch(`/api/database/articles/dataset/name/unavailable`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToInsert),
        });
      }

      this.artikelvorgangSpeichern(newdatasetid);
    },

    async artikelvorgangSpeichern(datasetid) {
      const datasetEventToInsert = {};

      if (Object.keys(datasetEventToInsert).length === 0) {
        Object.assign(datasetEventToInsert, {
          Vorgang_ID: 1,
          Artikel_ID: datasetid,
          Termin_ID: null,
          Kunden_ID: null,
          Bestellung_ID: null,
          // Datum wird automatisch erstellt
          Bearbeiter_ID: this.newDatasetEvent.Bearbeiter_ID,
        });
      }

      await fetch("/api/database/articles/dataset/event/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToInsert),
      });

      this.$root.actionSnackbar.show({
        dataset: "Artikel",
        action: "kopiert",
      });
      this.saved = true;
      this.$router.replace({ path: `/wws/database/articles/dataset/show/${datasetid}` });
    },
  },
};
</script>
